/** @jsx jsx */
import { jsx, Button, Flex, Box, Container, Styled, Grid } from 'theme-ui'
import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import BlockContent, { defaultSerializers } from '@sanity/block-content-to-react'

const Post = ({data}) => {

  const post = data.post

  return (
    <section>
      <SEO title={post.title} />
      <Container sx={{px: 4, mt: 5, maxWidth: 'medium'}}>
        <Img fluid={post.mainImage.asset.fluid} />
        <Styled.h1 sx={{mb: 1, mt: 4}}>{post.title}</Styled.h1>
        <Flex sx={{alignItems: 'center'}}>
          <Img fixed={post.author.image.asset.fixed} sx={{borderRadius: 'round', mr: 3}} />
          <Styled.h6 sx={{mb: '0px', fontWeight: 'bold'}}>{post.author.name}</Styled.h6>
        </Flex>

        <Grid columns={[1]}>
          <Box sx={{mb: 5}}>
            <BlockContent blocks={post._rawBody} serializers={defaultSerializers} projectId="9pk1j8nz" dataset="production"/>
          </Box>
        </Grid>
      </Container>
    </section>
  )
}

export default Post

export const query = graphql`
query($slug: String!) {
  post: sanityPost(slug: {current: {eq: $slug}}) {
    id
    title
    _rawBody
    slug {
      current
    }
    mainImage {
      asset {
        fluid(maxWidth: 1000) {
          ...GatsbySanityImageFluid_withWebp
        }
      }
    }
    author {
      name
      image {
        asset {
          fixed(width: 50) {
            ...GatsbySanityImageFixed_withWebp
          }
        }
      }
    }
  }
}
`
